import { supportedLocales } from 'src/utilities/i18n-helpers';

type PageType =
  | 'VideoDetail'
  | 'AudioDetail'
  | 'LandingPage'
  | 'Discover'
  | 'Live'
  | 'Audio'
  | 'Player'
  | 'Search'
  | 'SearchResults'
  | 'SearchFacet'
  | 'SearchFacetCollection'
  | 'Artist'
  | 'Partner'
  | 'Group'
  | 'Genre'
  | 'Epoch'
  | 'GridPage'
  | 'MyStage'
  | 'Tickets'
  | 'Payment'
  | 'Settings'
  | 'Login'
  | 'Signup'
  | 'ForgotPassword'
  | 'ResetPassword'
  | 'EmailChanged'
  | 'EmailConfirmed'
  | 'NewsletterConfirmation'
  | 'Legal'
  | 'TvConnect';

/**
 * A method that returns the type of the page that is tracked based on the pathname and search parameters
 * @param pathname the current pathname
 * @param searchParameters the current search parameters
 * @returns the type of the page
 * */
export const getPageTypeFromUrl = ({ pathname, search }: Pick<URL, 'pathname' | 'search'>): PageType | undefined => {
  if (search.includes('player=true')) {
    return 'Player';
  }
  // Get pathname without locale – locales are defined in the supportedLocales array in i18n-helpers.ts
  // e.g. /de/video/123 -> /video/123
  const pathnameWithoutLocale = pathname.replace(new RegExp(`^/(?:${supportedLocales.join('|')})(?:/|$)`), '/');

  if (pathnameWithoutLocale === '/') {
    return 'LandingPage';
  } else if (pathnameWithoutLocale.includes('/account/signup')) {
    return 'Signup';
  } else if (pathnameWithoutLocale.includes('/account/login')) {
    return 'Login';
  } else if (pathnameWithoutLocale.includes('/account/forgot')) {
    return 'ForgotPassword';
  } else if (pathnameWithoutLocale.includes('/account/email-confirmed')) {
    return 'EmailConfirmed';
  } else if (pathnameWithoutLocale.includes('/account/email-changed')) {
    return 'EmailChanged';
  } else if (pathnameWithoutLocale.includes('/account/password-reset')) {
    return 'ResetPassword';
  } else if (pathnameWithoutLocale.includes('/account/payment')) {
    return 'Payment';
  } else if (pathnameWithoutLocale.includes('/search/browse')) {
    return 'GridPage';
  } else if (/\/search\/(soloists|venue|ensembles|composers|conductors)\/\w+/.test(pathnameWithoutLocale)) {
    return 'SearchFacetCollection';
  } else if (/\/search\/(soloists|venue|ensembles|composers|conductors)/.test(pathnameWithoutLocale)) {
    return 'SearchFacet';
  } else if (pathnameWithoutLocale.includes('/search') && search.includes('q=')) {
    return 'SearchResults';
  } else if (pathnameWithoutLocale.includes('/search')) {
    return 'Search';
  } else if (pathnameWithoutLocale.includes('/video/')) {
    return 'VideoDetail';
  } else if (pathnameWithoutLocale.includes('/audio/album')) {
    return 'AudioDetail';
  } else if (pathnameWithoutLocale.includes('/discover')) {
    return 'Discover';
  } else if (pathnameWithoutLocale.includes('/live')) {
    return 'Live';
  } else if (pathnameWithoutLocale.includes('/artist/')) {
    return 'Artist';
  } else if (pathnameWithoutLocale.includes('/partner/')) {
    return 'Partner';
  } else if (pathnameWithoutLocale.includes('/group/')) {
    return 'Group';
  } else if (pathnameWithoutLocale.includes('/genre/')) {
    return 'Genre';
  } else if (pathnameWithoutLocale.includes('/epoch/')) {
    return 'Epoch';
  } else if (pathnameWithoutLocale.includes('/audio')) {
    return 'Audio';
  } else if (pathnameWithoutLocale.includes('/tickets')) {
    return 'Tickets';
  } else if (pathnameWithoutLocale.includes('/my-stage')) {
    return 'MyStage';
  } else if (pathnameWithoutLocale.includes('/newsletter/confirmation')) {
    return 'NewsletterConfirmation';
  } else if (pathnameWithoutLocale.includes('/settings')) {
    return 'Settings';
  } else if (/\/(terms|imprint|privacy|notice-on-direct-marketing)/.test(pathnameWithoutLocale)) {
    return 'Legal';
  } else if (pathnameWithoutLocale.includes('/connect')) {
    return 'TvConnect';
  } else {
    return undefined;
  }
};
