import { PropsWithChildren } from 'react';
import { Middleware, SWRConfig, SWRConfiguration } from 'swr';
import { useCurrentLocale } from 'src/hooks/use-current-locale';
import { SupportedLocale } from 'src/types';

/**
 * The GraphQL and Algolia APIs return different results based on the current locale.
 * This middleware ensures that the SWR cache is invalidated when the locale changes by adding the locale to the cache key.
 * @param locale The current locale
 * @returns A SWR middleware
 */
function localizeMiddleware(locale: SupportedLocale): Middleware {
  return (useSWRNext) => (key, fetcher, config) => {
    /**
     * Only add the locale to the cache key if it is a string.
     * When the cache key is a function, it is the responsibility of the function to include the locale in the cache key.
     * Similarly, when the cache key is `null` or `undefined`, the cache key should not be modified.
     * @see https://swr.vercel.app/docs/options
     * @see https://swr.vercel.app/docs/conditional-fetching
     */
    const keyWithLocale = typeof key === 'string' ? `${locale}/${key}` : key;
    return useSWRNext(keyWithLocale, fetcher, config);
  };
}

/**
 * A component that sets the default config for any useSWR calls in any of its children.
 * Therefore it should sit as high up the React node tree as possible.
 */
export default function APIRoot({ children }: PropsWithChildren<unknown>) {
  const currentLocale = useCurrentLocale();

  const globalSWRConfig: SWRConfiguration = {
    use: [localizeMiddleware(currentLocale)],
  };

  return <SWRConfig value={globalSWRConfig}>{children}</SWRConfig>;
}
