import { Fragment, ComponentPropsWithoutRef, PropsWithChildren, forwardRef, Ref } from 'react';
import { Menu, Transition, MenuButton, MenuItems, MenuItem } from '@headlessui/react';
import type { StagePlusIcon } from '@stageplus/icons/react';
import clsx from 'clsx';
import Link from 'next/link';

/**
 * A ContextMenu can toggle a dropdown list with a button
 * It is a container around a ContextMenu.Button and ContextMenu.Items
 * @example
 * <ContextMenu>
 *   <ContextMenu.Button aria-label="More">
 *     Options
 *   </ContextMenu.Button>
 *   <ContextMenu.Items>
 *     <ContextMenu.Item label="Share this item" icon={ShareIcon} />
 *   </ContextMenu.Items>
 * </ContextMenu
 */
function ContextMenu({ className = '', ...props }: ComponentPropsWithoutRef<'div'>) {
  return <Menu as="div" className={clsx('relative', className)} {...props} />;
}

/**
 * ContextMenuItems is exposed as ContextMenu.Items and extends Menu.Items
 * It should contain ContextMenu.Item components and draws a container around them alongside an animation
 */
function ContextMenuItems({ children }: PropsWithChildren<unknown>) {
  return (
    <Transition
      as={Fragment}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-75"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-75"
    >
      <MenuItems className="absolute right-2 z-50 flex w-60 origin-top-right flex-col divide-y divide-divider overflow-hidden rounded-xl border-4 border-white bg-textWhite shadow-lg ring-2 ring-brandYellowC1/5 focus:outline-none">
        {children}
      </MenuItems>
    </Transition>
  );
}

/**
 * A `ContextMenuItem` is exposed as `ContextMenu.Item` and extends `Menu.Item`
 *
 * It should always be a child of `ContextMenu.Items`. It can either be a link or a button. To be a link, pass a
 * `href` prop. To be a button, pass an `onClick` prop. The `icon` prop is optional.
 *
 * @example
 * ```tsx
 * <ContextMenu.Items>
 *   <ContextMenu.Item href="/my-stage" icon={FavoriteIcon}>MySTAGE+</ContextMenu.Item>
 *   <ContextMenu.Item onClick={logout} icon={LogoutIcon}>Log out</ContextMenu.Item>
 * </ContextMenu.Items>
 * ```
 */
type ContextMenuItemProps = PropsWithChildren<
  | {
      href: string;
      icon?: StagePlusIcon;
    }
  | {
      onClick: () => void;
      icon?: StagePlusIcon;
    }
>;

function ContextMenuItem(props: ContextMenuItemProps) {
  // If the item has a `href` prop, it is a link
  if ('href' in props) {
    const { children, href, icon, ...rest } = props;
    return (
      <MenuItem {...rest}>
        {({ focus, disabled }) => (
          <ContextMenuLink href={href}>
            <ContextMenuItemWrapper icon={icon} focus={focus} disabled={disabled}>
              {children}
            </ContextMenuItemWrapper>
          </ContextMenuLink>
        )}
      </MenuItem>
    );
  }

  // If there is no `href` prop, it is a button
  const { children, icon, onClick, ...rest } = props;
  return (
    <MenuItem as="button" onClick={onClick} {...rest}>
      {({ focus, disabled }) => (
        <ContextMenuItemWrapper icon={icon} focus={focus} disabled={disabled}>
          {children}
        </ContextMenuItemWrapper>
      )}
    </MenuItem>
  );
}

/**
 * A `ContextMenuLink` is used by the `ContextMenuItem` component when a `href` prop is passed.
 *
 * It extends the Next.js `Link` component, but since the `Link` component does not forward unknown props to the
 * underlying `a` element, we need to wrap the component in a `forwardRef`.
 *
 * If we don’t, the parent ContextMenu will not close when the link is clicked.
 */
const ContextMenuLink = forwardRef(function ContextMenuLink(
  props: PropsWithChildren<{ href: string }>,
  ref: Ref<HTMLAnchorElement>,
) {
  const { href, children, ...rest } = props;

  return (
    <Link href={href} ref={ref} {...rest}>
      {children}
    </Link>
  );
});

/**
 * A `ContextMenuItemWrapper` is used by the `ContextMenuItem` component to make the items all look the same and
 * optionally have an icon. It also has optional active and disabled visual states.
 */
type ContextMenuItemWrapperProps = PropsWithChildren<{
  icon?: StagePlusIcon;
  focus?: boolean;
  disabled?: boolean;
}>;

function ContextMenuItemWrapper(props: ContextMenuItemWrapperProps) {
  const { children, icon: IconComponent, focus, disabled } = props;

  return (
    <div
      className={clsx(
        'dg-text-regular-4 flex h-11 select-none flex-row items-center justify-between px-3 text-mainBgBlueC2',
        focus && 'bg-greyG3/5',
        disabled && 'opacity-50',
      )}
    >
      {children}
      {IconComponent && <IconComponent aria-hidden />}
    </div>
  );
}

export default Object.assign(ContextMenu, {
  Button: MenuButton,
  Items: ContextMenuItems,
  Item: ContextMenuItem,
});
