import { GraphQLError } from 'graphql';

export const GraphQLErrorType = {
  Unauthenticated: 'UNAUTHENTICATED',
  TokenExpired: 'TOKEN_EXPIRED',
  NotFound: 'NOT_FOUND',
} as const;

/**
 * Checks whether a given GraphQLError object matches a specific GraphQL error type.
 *
 * @param {GraphQLError} error - The GraphQLError instance to be checked.
 * @param {keyof typeof GraphQLErrorType} errorType - The GraphQL error type to match.
 * @returns {boolean} `true` if the error matches the specified type, `false` otherwise.
 */
export function matchesGraphQLErrorType(
  error: GraphQLError,
  errorType: (typeof GraphQLErrorType)[keyof typeof GraphQLErrorType],
): boolean {
  const code = 'code' in error && typeof error.code === 'string' ? error.code.toUpperCase() : undefined;
  const message = 'message' in error && typeof error.message === 'string' ? error.message.toUpperCase() : undefined;
  switch (errorType) {
    case GraphQLErrorType.Unauthenticated:
    case GraphQLErrorType.TokenExpired: {
      return code === errorType;
    }
    case GraphQLErrorType.NotFound: {
      return message === errorType;
    }
  }
}
