import { forwardRef, ReactNode, ButtonHTMLAttributes } from 'react';
import clsx from 'clsx';

/**
 * Available color variations for the button.
 */
export enum ButtonBG {
  white,
  yellow,
}

/**
 * Props for the button component.
 * It accepts all the props of the native button element plus the following:
 **/
type ButtonProps = {
  bg?: ButtonBG;
  loading?: boolean;
  dataTest?: string;
} & ButtonHTMLAttributes<HTMLButtonElement>;

/**
 * Props for the button component with an icon.
 * It accepts all the props of the native button element plus the following:
 **/
type ButtonWithIconProps = ButtonProps & { icon: ReactNode; active?: boolean };

/**
 * A round color button with an icon in the middle
 * @param bg color, one of the possible ButtonBG values, default is white
 * @param onClick the click handler
 * @param icon Icon component to be displayed in the button
 * @param className class name to be added to the button, note: custom `className` has to define the dimensions explicitly
 * @param dataTest data-test param to be added for the integration tests
 * @returns
 */
export const ButtonIconRound = forwardRef<HTMLButtonElement, ButtonWithIconProps>(function ButtonIconRound(
  props: ButtonWithIconProps,
  ref,
) {
  const { bg = ButtonBG.white, onClick, icon, className = 'w-8 h-8', dataTest, disabled, title, ...restProps } = props;

  const backgroundClassName =
    bg === ButtonBG.white ? 'bg-white hover:bg-white/70' : 'bg-brandYellowC1 hover:bg-brandYellowC1/70';

  return (
    <button
      className={clsx(
        'group relative flex items-center justify-center rounded-full p-0',
        // grey out the button if disabled
        disabled ? `bg-darkBlueC7 text-white/35` : `${backgroundClassName} text-deepBlueC4`,
        className,
      )}
      onClick={onClick}
      aria-label={title}
      title={title}
      data-test={dataTest}
      disabled={disabled}
      ref={ref}
      {...restProps}
    >
      <div className={clsx('relative z-20', disabled && 'opacity-35')}>{icon}</div>
    </button>
  );
});

/**
 * A tiny transparent icon-only button of 44x44px
 * @param onClick the click handler
 * @param icon Icon component to be displayed in the button
 * @param className class name to be added to the button, note: custom `className` has to define the dimensions explicitly
 * @param dataTest data-test param to be added for the integration tests
 * @returns
 */
export const ButtonIconOnly = forwardRef<HTMLButtonElement, ButtonWithIconProps>(function ButtonIconOnly(
  props: ButtonWithIconProps,
  ref,
) {
  const { onClick, icon, className = 'h-11 w-11', dataTest, active, title, ...restProps } = props;

  return (
    <button
      ref={ref}
      className={clsx(
        'group flex items-center justify-center rounded-full p-0',
        active && 'text-brandYellowC1',
        className,
      )}
      onClick={onClick}
      aria-label={title}
      title={title}
      data-test={dataTest}
      {...restProps}
    >
      {icon}
    </button>
  );
});

/**
 * A round icon-only button with a border around it
 * @param label an accessible label of the button
 * @param onClick the click handler
 * @param icon Icon component to be displayed in the button
 * @param className class name to be added to the button, note: custom `className` has to define the dimensions explicitly
 * @param dataTest data-test param to be added for the integration tests
 * @returns
 */
export const ButtonIconWithBorder = forwardRef<HTMLButtonElement, ButtonWithIconProps>(function ButtonIconWithBorder(
  props: ButtonWithIconProps,
  ref,
) {
  const { icon, ...restProps } = props;
  return (
    <ButtonIconOnly
      icon={
        <div className="flex size-8 items-center justify-center rounded-full border-2 border-white mouse:hover:bg-white mouse:hover:text-mainBgBlueC2">
          {icon}
        </div>
      }
      ref={ref}
      {...restProps}
    />
  );
});
