import { forwardRef, ButtonHTMLAttributes, ComponentProps } from 'react';
import clsx from 'clsx';
import Link from 'next/link';
import CircularProgress from 'src/components/common/circular-progress';

/**
 * Display an animated loading indicator on a button,
 * Note: the container should have a `position: relative` or 'absolute'
 * @example <ButtonLoadingAnimation active={loading} />
 */
const ButtonLoadingAnimation = ({ active }: { active?: boolean }) => {
  return (
    <div
      className={clsx(
        'pointer-events-none absolute inset-0 z-50 flex size-full items-center justify-center bg-greyG2 transition-opacity duration-150',
        active ? 'opacity-100' : 'opacity-0',
      )}
      data-test="button-loading-animation"
    >
      {active && <CircularProgress />}
    </div>
  );
};

export type TextButtonProps = {
  disabled?: boolean;
  variation?: 'primary' | 'secondary' | 'outline' | 'payment' | 'newsletter' | 'danger' | 'primary-header';
  loading?: boolean;
  children: React.ReactNode;
  className?: string;
  fullWidth?: boolean;
};

type TextButtonClassesProps = { variation: TextButtonProps['variation']; customClassName?: string; fullWidth: boolean };

const textButtonClasses = ({ variation, customClassName, fullWidth }: TextButtonClassesProps) =>
  clsx(
    'group relative inline-flex min-h-11 select-none items-center justify-center overflow-hidden whitespace-nowrap rounded-[4px] px-6 focus-visible:focus-outline',
    fullWidth ? 'w-full' : 'w-auto',
    variation === 'primary' && 'dg-text-medium-9 bg-brandYellowC1 text-mainBgBlueC2 lg:min-h-[54px]',
    variation === 'secondary' && 'dg-text-medium-9 bg-buttonBlueC8 lg:min-h-[54px]',
    variation === 'danger' && 'dg-text-medium-9 bg-liveNowRedC9 lg:min-h-[54px]',
    variation === 'outline' &&
      'dg-text-medium-9 border border-buttonBlueC8 lg:min-h-[54px] mouse:hover:border-dividerLight mouse:hover:bg-buttonBlueC8',
    variation === 'payment' && 'dg-text-medium-4 bg-white text-mainBgBlueC2 hover:bg-brandYellowC1',
    variation === 'newsletter' && 'dg-text-regular-6 bg-white text-mainBgBlueC2 lg:min-h-[52px]',
    variation === 'primary-header' && 'dg-text-regular-4 bg-brandYellowC1 text-mainBgBlueC2 lg:min-h-[48px]',
    'from-buttonHoverGradient to-buttonHoverGradient hover:bg-gradient-to-r',
    customClassName,
  );

const textButtonDisabledClasses = ({ variation, customClassName, fullWidth }: TextButtonClassesProps) =>
  clsx(
    'group relative inline-flex min-h-11 select-none items-center justify-center overflow-hidden whitespace-nowrap rounded-sm px-6 text-white/35',
    fullWidth ? 'w-full' : 'w-auto',
    variation === 'primary' && 'dg-text-medium-9 bg-darkBlueC7 lg:min-h-[54px]',
    variation === 'secondary' && 'dg-text-medium-8 bg-buttonBlueC8 lg:min-h-[52px]',
    variation === 'danger' && 'dg-text-medium-8 2xl:min-h-[52px]',
    variation === 'outline' && 'dg-text-medium-8 2xl:min-h-[52px]',
    variation === 'payment' && 'dg-text-medium-4',
    customClassName,
  );

/**
 * Text button component
 *  @param children the text to be displayed
 *  @param variation variation of the button style (primary, secondary, outline, payment)
 *  @param loading the loading state of the button
 *  @param disabled is the button disabled?
 *  @param className custom class name to be added to the button
 *  @param fullWidth should the button fill the width of the container, default is false
 */
export const TextButton = forwardRef<HTMLButtonElement, TextButtonProps & ButtonHTMLAttributes<HTMLButtonElement>>(
  function TextButton(props, ref) {
    const { children, variation = 'primary', disabled, className, loading, fullWidth = false, ...restProps } = props;
    const buttonClasses = disabled ? textButtonDisabledClasses : textButtonClasses;

    return (
      <button
        className={buttonClasses({ variation, customClassName: className, fullWidth })}
        disabled={disabled}
        {...restProps}
        ref={ref}
      >
        <span className="relative flex items-center justify-center">{children}</span>
        <ButtonLoadingAnimation active={loading} />
      </button>
    );
  },
);

/**
 * Text button that is a Link component
 *  @param href The URL to link to
 *  @param children the text to be displayed in the button
 *  @param variation variation of the button style (primary, secondary, outline, payment)
 *  @param loading the loading state of the button
 *  @param className custom class name to be added to the button
 *  @param fullWidth should the button fill the width of the container, default is false
 */
export function TextButtonLink(props: TextButtonProps & ComponentProps<typeof Link>) {
  const { children, variation = 'primary', className, loading, fullWidth = false, ...restProps } = props;

  return (
    <Link {...restProps} className={textButtonClasses({ variation, customClassName: className, fullWidth })}>
      {/* button title */}
      <span className="relative flex items-center justify-center text-center">{children}</span>
      <ButtonLoadingAnimation active={loading} />
    </Link>
  );
}
