import { RefObject, useState, useEffect, useCallback } from 'react';

/**
 * A hook that provides a list of all available text tracks for the video.
 * @param videoElRef A reference to the video element.
 * @param videoURL The URL of the video.
 * @returns an array of text tracks.
 */
const useVideoTextTracks = ({
  videoElRef,
  videoURL,
}: {
  videoElRef: RefObject<HTMLVideoElement | undefined>;
  videoURL?: string;
}): TextTrack[] => {
  // text tracks / subtitles of the current video context
  const [textTracks, setTextTracks] = useState<TextTrack[]>([]);
  // reset the text tracks when the video url changes
  useEffect(() => {
    setTextTracks([]);
  }, [videoURL]);

  // listen to the video object changes and update the global state
  const onVideoStatusUpdate = useCallback(
    (event: Event) => {
      if (textTracks.length > 0) {
        // if we already know about the text tracks, no need to continue
        return;
      }
      const video = event.target;
      // if there are any text tracks and we don't know about them yet
      if (video instanceof HTMLVideoElement) {
        // get text tracks / subtitles sources from the current video context
        const textTracksRaw = video.textTracks;
        if (textTracksRaw) {
          const textTracksArray = [];
          for (const textTrack of textTracksRaw) {
            // if the text track has no language set, we can ignore it, as it might be e.g an id3 track
            if (!textTrack.language) break;
            // as of 2022-05, the HLS encoding by G&L forces the first subtitle to be the default one
            // so we need to reset them all to disabled until a user chooses a specific one
            textTrack.mode = 'disabled';
            textTracksArray.push(textTrack);
          }
          // update the state with the new text tracks
          setTextTracks(textTracksArray);
        }
      }
    },
    [textTracks.length],
  );

  // observe every time the video tag actually changes,e.g. when video url is changed
  // so the listeners can be attached top the newly created video element
  const videoElement = videoElRef.current;

  useEffect(() => {
    // observe all video element status changes
    if (videoElement) {
      // listen when the metadata is loaded and get the text tracks
      videoElement.addEventListener('loadedmetadata', onVideoStatusUpdate);
      // usually it should be enough to listen to the 'loadeddata' event, but on iOS Safari
      // only basic events like play, pause, seek, etc. are fired
      videoElement.addEventListener('play', onVideoStatusUpdate);
    }
    return () => {
      if (videoElement) {
        videoElement.removeEventListener('loadedmetadata', onVideoStatusUpdate);
        videoElement.removeEventListener('play', onVideoStatusUpdate);
      }
    };
  }, [onVideoStatusUpdate, videoElement]);

  return textTracks;
};

/**
 * A method that allows to display or hide a particular text track
 * @param selected - selected text track, if you wish to hide any subtitle, just pass ùndefined`
 * @param textTracks - an array of the text tracks of the video
 */
export const toggleVideoTextTrack = ({
  selectedTrack,
  textTracks,
}: {
  selectedTrack?: TextTrack;
  textTracks?: TextTrack[];
}) => {
  // check if we are toggling off the already showing text track
  const wasShowing = selectedTrack?.mode === 'showing';
  if (textTracks) {
    for (const sub of textTracks) {
      // reset all subtitles first
      sub.mode = 'disabled';
    }
    // then, if we are enabling a previously invisible text track,
    // change its mode to `showing`
    if (selectedTrack && !wasShowing) {
      selectedTrack.mode = 'showing';
    }
  }
};

export default useVideoTextTracks;
