import { RefObject, useCallback, useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { SubmitErrorHandler, FieldValues } from 'react-hook-form';
import { AudioDetailPageQuery, PublicationLevel, VideoDetailPageQuery } from 'generated/graphql';
import { useCurrentLocale } from 'src/hooks/use-current-locale';
import useIntersectionObserver from 'src/hooks/use-intersection-observer';
import { getContentTypeFromUrl } from 'src/tracking/content-type';
import { FavoriteEventPayload, trackFavorites } from 'src/tracking/favorites';
import { pushToGoogleTagManager } from 'src/tracking/gtm';
import { getPageTypeFromUrl } from 'src/tracking/page-type';
import {
  CouponEventPayload,
  PromotionEventPayload,
  SubscriptionEventPayload,
  SubscriptionImpressionEventPayload,
  trackCoupon,
  trackPromotion,
  trackSubscriptionImpression,
  trackSubscriptionInteraction,
  trackPostSubscriptionForm,
  SubscriptionCanceledEventPayload,
  trackSubscriptionCanceled,
  PostSubscriptionDefaultModalPayload,
} from 'src/tracking/track-ecommerce';
import {
  PlaybackEventPayload,
  PlayerControlsEventPayload,
  SubtitleEventPayload,
  trackPlayback,
  trackPlayerControls,
  trackSubtitle,
} from 'src/tracking/track-playback';
import {
  SignupEventPayload,
  LoginEventPayload,
  ForgotPasswordEventPayload,
  trackSignup,
  trackLogin,
  trackForgotPassword,
  NewsletterEventPayload,
  trackNewsletter,
  trackSignupConfirmation,
  SignupConfirmationEventPayload,
  trackUserProperties,
  UserPropertiesEventPayload,
  CtaSignupLoginEventPayload,
  trackSignupLoginCta,
  LogoutEventPayload,
  trackLogout,
} from 'src/tracking/track-user';
import { SupportedLocale } from 'src/types';
import { getNodePath, isProductionHost } from 'src/utilities/url-helpers';

/** define different types of events that can be tracked here */

// virtual page view tracking type
type VirtualPageViewEventPayload = { eventName: 'VirtualPageView'; url: string; title: string };
// error event types
type ErrorPageEventPayload = { eventName: 'ErrorPageImpression'; errorCode: number };
type TrackedNetwork = 'Facebook' | 'Twitter' | 'LinkedIn' | 'WhatsApp' | 'Email';
// share event types
type ShareEventPayload = { eventName: 'Share'; network: TrackedNetwork | 'Other' | 'UrlCopy' };
// navigation event types
type NavigationEventPayload = { eventName: 'Navigation'; action: string; label: string };
type SearchEventPayload = { eventName: 'Search'; query: string };
type ContentDetailImpressionPayload = {
  eventName: 'VideoDetailPageImpression' | 'AudioDetailPageImpression';
  content:
    | Extract<VideoDetailPageQuery['node'], { __typename: 'Video' | 'LiveConcert' | 'VodConcert' }>
    | Extract<AudioDetailPageQuery['album'], { __typename: 'Album' }>;
};

// all events that can be tracked
type TrackEventPayload =
  | CouponEventPayload
  | CtaSignupLoginEventPayload
  | ErrorPageEventPayload
  | FavoriteEventPayload
  | ForgotPasswordEventPayload
  | LoginEventPayload
  | LogoutEventPayload
  | NavigationEventPayload
  | NewsletterEventPayload
  | PlaybackEventPayload
  | SubtitleEventPayload
  | PlayerControlsEventPayload
  | PromotionEventPayload
  | SearchEventPayload
  | ShareEventPayload
  | SignupConfirmationEventPayload
  | SignupEventPayload
  | SubscriptionCanceledEventPayload
  | PostSubscriptionDefaultModalPayload
  | SubscriptionEventPayload
  | SubscriptionImpressionEventPayload
  | UserPropertiesEventPayload
  | VirtualPageViewEventPayload
  | ContentDetailImpressionPayload;

/**
 * track share events in analytics and GTM
 * based on the spec from:
 * https://guide.trakken.de/5f807081e90fd60009b13d1b/published/60829afcb4ea24000aab8185#chapter3.6.1
 */
const trackShare = ({ network }: ShareEventPayload) => {
  pushToGoogleTagManager({
    event: 'Generic Event',
    event_name: 'share',
    share: {
      social_network: network,
    },
  });
};

/**
 * Track the display of error pages
 * based on the spec from:
 * https://guide.trakken.de/5f807081e90fd60009b13d1b/published/60829afcb4ea24000aab8185#chapter2.4.1
 * @param errorCode a number id of the error, e.g. `404` or `500`
 */
const trackErrorPage = ({ errorCode }: ErrorPageEventPayload) => {
  const pagePath = `${document.location.pathname}${document.location.search ? '?' + document.location.search : ''}`;
  pushToGoogleTagManager({
    event: 'Page Meta',
    PagePath: `/${errorCode}?page=${encodeURIComponent(pagePath)}&from=${encodeURIComponent(document.referrer)}`,
  });
};

/**
 * Track the additional navigation events
 * based on the spec from:
 * https://guide.trakken.de/5f807081e90fd60009b13d1b/published/60829afcb4ea24000aab8185#chapter3.3
 * @param errorCode a number id of the error, e.g. `404` or `500`
 */
const trackNavigation = ({ action, label }: NavigationEventPayload) => {
  pushToGoogleTagManager({
    event: 'Generic Event',
    event_name: 'navigation',
    navigation: {
      navigation_bar: action,
      navigation_item: label,
    },
  });
};

/**
 * Track the search events
 * based on the spec from Google Analytics:
 *
 */
const trackSearch = ({ query }: SearchEventPayload) => {
  pushToGoogleTagManager({
    event: 'search',
    search_term: query,
  });
};

/**
 * Track page views when the user navigates to a new page using history.push/pop
 * based on the spec from:
 * https://guide.trakken.de/5f807081e90fd60009b13d1b/published/60829afcb4ea24000aab8185#chapter2.5
 */
const trackVirtualPageView = ({ url, title }: VirtualPageViewEventPayload) => {
  pushToGoogleTagManager({
    event: 'Virtual Page View',
    PagePath: url,
    virtPageTitle: title,
    virtPath: 'true',
  });
};

/**
 * Track content detail impressions for video and audio detail pages
 * @todo share the `content_detail_view.content_*` fields with the playback tracking events defined in `track-playback.ts`
 */
function trackContentDetailImpression({ content }: ContentDetailImpressionPayload) {
  if (!content) return;

  const contentPublicationLevel = {
    Album: PublicationLevel.TicketRequired, // Albums are always paid
    LiveConcert: content.__typename === 'LiveConcert' ? content.publicationLevel : PublicationLevel.TicketRequired,
    Trailer: PublicationLevel.NoAuthentication, // Trailers are always free
    Video: content.__typename === 'Video' ? content.publicationLevel : PublicationLevel.TicketRequired,
    VodConcert: content.__typename === 'VodConcert' ? content.publicationLevel : PublicationLevel.TicketRequired,
  }[content.__typename];

  const soloists = ('soloists' in content && content.soloists?.edges) || [];
  const conductors = ('conductors' in content && content.conductors?.edges) || [];
  const artists = [...soloists, ...conductors].map((edge) => edge.node);

  pushToGoogleTagManager({
    event: 'Generic Event',
    event_name: 'content_detail_view',
    content_detail_view: {
      content_access_type: contentPublicationLevel === PublicationLevel.TicketRequired ? 'paid' : 'free',
      content_container_artist_id: artists.length > 0 ? artists.map((node) => node.id).join(',') : undefined,
      content_container_artist_name: artists.length > 0 ? artists.map((node) => node.name).join(',') : undefined,
      content_container_id: content.id,
      content_container_name: content.title,
      content_item_artist_id: undefined,
      content_item_artist_name: undefined,
      content_item_id: undefined,
      content_item_name: undefined,
      content_link: getNodePath(content),
      content_subtype: {
        Album: undefined,
        LiveConcert: content.__typename === 'LiveConcert' ? content.liveConcertType : undefined,
        Trailer: undefined,
        Video: content.__typename === 'Video' ? content.videoType : undefined,
        VodConcert: content.__typename === 'VodConcert' ? content.vodConcertType : undefined,
      }[content.__typename],
      content_type: content.__typename,
    },
  });
}

/**
 * A generic tracking method
 * Please use this method instead of the individual tracking methods if possible, to help maintain an overview of all tracked events here
 * @param payload - the payload to track, usually consists of an event name and an additional data object
 */
export const trackEvent = (payload: TrackEventPayload) => {
  switch (payload.eventName) {
    case 'EECpromotionImpression':
    case 'EECpromotionClick': {
      trackPromotion(payload);
      break;
    }
    case 'SubscriptionImpression': {
      trackSubscriptionImpression(payload);
      break;
    }
    case 'PostSubscriptionFormImpression':
    case 'PostSubscriptionFormSubmit': {
      trackPostSubscriptionForm(payload);
      break;
    }
    case 'SubscriptionClick':
    case 'SubscriptionCheckout':
    case 'SubscriptionPurchase': {
      trackSubscriptionInteraction(payload);
      break;
    }
    case 'SubscriptionCanceled': {
      trackSubscriptionCanceled(payload);
      break;
    }
    case 'CouponRedeemed': {
      trackCoupon(payload);
      break;
    }
    case 'ErrorPageImpression': {
      trackErrorPage(payload);
      break;
    }
    case 'Share': {
      trackShare(payload);
      break;
    }
    case 'SignupDisplayed':
    case 'SignupError':
    case 'SignupSuccess': {
      trackSignup(payload);
      break;
    }
    case 'SignupConfirmationRequired':
    case 'SignupConfirmationSuccess':
    case 'SignupConfirmationFailure': {
      trackSignupConfirmation(payload);
      break;
    }
    case 'LoginDisplayed':
    case 'LoginError':
    case 'LoginSuccess': {
      trackLogin(payload);
      break;
    }
    case 'Logout': {
      trackLogout();
      break;
    }
    case 'UserProperties': {
      trackUserProperties(payload);
      break;
    }
    case 'NewsletterStart':
    case 'NewsletterSuccess':
    case 'NewsletterError':
    case 'NewsletterConfirmation': {
      trackNewsletter(payload);
      break;
    }
    case 'ForgotPasswordDisplayed':
    case 'ForgotPasswordError':
    case 'ForgotPasswordSuccess': {
      trackForgotPassword(payload);
      break;
    }
    case 'FavoriteRemoved':
    case 'FavoriteAdded': {
      trackFavorites(payload);
      break;
    }
    case 'VirtualPageView': {
      trackVirtualPageView(payload);
      break;
    }
    case 'Search': {
      trackSearch(payload);
      break;
    }
    case 'Navigation': {
      trackNavigation(payload);
      break;
    }
    case 'CtaLogin':
    case 'CtaForgotPassword':
    case 'CtaSignup': {
      trackSignupLoginCta(payload);
      break;
    }
    case 'PlaybackStart':
    case 'PlaybackPlay':
    case 'PlaybackPause':
    case 'PlaybackSkip':
    case 'PlaybackComplete': {
      trackPlayback(payload);
      break;
    }
    case 'SubtitleOpen':
    case 'SubtitleLanguageSelected': {
      trackSubtitle(payload);
      break;
    }
    case 'PlayerControlsPlay':
    case 'PlayerControlsPause':
    case 'PlayerControlsSkip': {
      trackPlayerControls(payload);
      break;
    }
    case 'VideoDetailPageImpression':
    case 'AudioDetailPageImpression': {
      trackContentDetailImpression(payload);
      break;
    }
    default: {
      break;
    }
  }
};

/**
 * An observed tracking hook
 * @param observationRef - a ref to the DOM element to track
 * @param impressionPayload - the payload to track, usually consists of an event name and an additional data object
 * @param freezeOnceVisible - track the event only once, even if the element is visible again
 * @returns an object with a `trackEvent` method that can be used to manually trigger the tracking
 */
function useObservedTracking<T extends HTMLElement>(
  observationRef: RefObject<T>,
  impressionPayload: TrackEventPayload,
  freezeOnceVisible: boolean,
) {
  // watch when the DOM element is visible in the viewport, and trigger the callback only once
  const observer = useIntersectionObserver(observationRef, { threshold: 0.1, freezeOnceVisible });
  // keep a reference to the timestamp so we can track the event only once
  const trackingTimeRef = useRef<number>();

  useEffect(() => {
    // track impression when the observable element appears in the viewport
    if (observer?.isIntersecting && trackingTimeRef.current !== observer?.time) {
      trackEvent(impressionPayload);
      trackingTimeRef.current = observer?.time;
    }
  }, [impressionPayload, observer?.isIntersecting, observer?.time]);

  return {
    // provide a way to track the event manually
    trackEvent,
  };
}

/**
 * An impression tracking hook
 * @param impressionPayload - the payload to track, usually consists of an event name and an additional data object
 * @param trackImpressionOnlyOnce - track the event only once, even if the element is visible again, for repeated impressions set to `false`
 * @param enabled - you can disable the tracking by setting this to `false`, e.g. until the data is loaded into a component
 * @returns an object with a impressionObserverRef to attach to the tracked element and
 * a `trackEvent` method that can be used to manually trigger the tracking
 * @example
 *   const { impressionObserverRef } = useImpressionTracking({ eventName: 'BannerVisible' });
 *   ...
 *  <div ref={impressionObserverRef} />
 */
export function useImpressionTracking(
  impressionPayload: TrackEventPayload,
  options?: {
    trackImpressionOnlyOnce?: boolean;
    enabled?: boolean;
  },
) {
  const { trackImpressionOnlyOnce = true, enabled = true } = options || {};
  // a ref to the DOM element to track
  const impressionObserverRef = useRef(null);
  // a null ref to disable the tracking
  const nullObserverRef = useRef(null);
  // observe the appearance of the DOM element in the viewport and track it, pass the null ref to disable the tracking
  const { trackEvent } = useObservedTracking(
    enabled ? impressionObserverRef : nullObserverRef,
    impressionPayload,
    trackImpressionOnlyOnce,
  );

  return {
    /** a ref to the DOM element to track */
    impressionObserverRef,
    /** a method to manually trigger the tracking, if needed */
    trackEvent,
  };
}

/**
 * A search tracking hook
 * @param searchQuery - the search query to track
 * @param trackWaitSeconds - so we should no send the tracking event immediately, but wait until user has finished typing
 */
export const useSearchTracking = (searchQuery?: string, trackWaitSeconds = 1.5) => {
  // store the last tracked search query
  const lastTrackedQuery = useRef<string | undefined>(undefined);

  useEffect(() => {
    if (searchQuery && searchQuery !== lastTrackedQuery.current) {
      const searchTimeout = window.setTimeout(() => {
        // first track the virtual page view
        trackEvent({
          eventName: 'VirtualPageView',
          url: `${document.location.pathname}${document.location.search}`,
          title: document.title,
        });
        // then track the search event
        trackEvent({ eventName: 'Search', query: searchQuery });
        // update the last tracked query
        lastTrackedQuery.current = searchQuery;
      }, trackWaitSeconds * 1000);

      return () => {
        clearTimeout(searchTimeout);
      };
    }
  }, [searchQuery, trackWaitSeconds]);
};

/**
 * Allow to track page views when the user navigates to a new page using history.push/pop/replace
 * Note: the router changes with `shallow: true` are not tracked, as they are not real page views
 */
export const useVirtualPageViewTracking = () => {
  const { events } = useRouter();
  const currentLocale = useCurrentLocale();
  const handleRouteChange = useCallback(
    (url: string, options?: { shallow: boolean }) => {
      if (options?.shallow) {
        // don't track shallow navigation, they can be done explicitly with trackEvent
        return;
      }
      trackPageMeta(new URL(window.location.href), currentLocale);
      trackEvent({ eventName: 'VirtualPageView', url, title: document.title });
    },
    [currentLocale],
  );
  useEffect(() => {
    // track each page view in Google Analytics
    events.on('routeChangeComplete', handleRouteChange);
    return () => {
      events.off('routeChangeComplete', handleRouteChange);
    };
  }, [events, handleRouteChange]);
};

/**
 * A helper function to compare two event payloads,
 * as in some cases we don't want to track the same event twice
 */
function arePayloadsEqual(a: TrackEventPayload, b?: TrackEventPayload) {
  return JSON.stringify(a) === JSON.stringify(b);
}

/**
 * A hook that allows to track an event only one time.
 */
export function useTrackEventOnce() {
  // store the last tracked payload
  const payloadTracked = useRef<TrackEventPayload>();
  /**
   * will track an event only if it hasn't been tracked before
   * @param payload - the payload to track
   */
  const trackOnce = (payload: TrackEventPayload) => {
    if (!arePayloadsEqual(payload, payloadTracked.current)) {
      trackEvent(payload);
      payloadTracked.current = payload;
    }
  };
  return trackOnce;
}

/**
 * Track Page Meta data events
 * It should be called on each page load as FIRST tracking event and on BEFORE each page navigation
 * @param url - the current URL
 * @param currentLocale - the current locale
 */

const trackPageMeta = (url: URL, currentLocale: SupportedLocale) => {
  // get the content type from the current route
  const contentType = getContentTypeFromUrl({
    pathname: url.pathname,
    search: url.search,
  });
  const pageType = getPageTypeFromUrl({
    pathname: url.pathname,
    search: url.search,
  });
  // check if we are on a production host
  const isProduction = isProductionHost(url);

  // track the page metadata, for now only once per page load
  // but eventually we could pass more data each time when the user navigates to a new page
  pushToGoogleTagManager({
    event: 'Page Meta',
    PageDivision: 'Deutsche Grammophon GmbH',
    PageType: pageType,
    Language: currentLocale,
    ArtistGenre: 'KLASSIK',
    ArtistName: 'Various Artists',
    Framework: 'Next JS',
    TrafficType: 'External',
    Environment: isProduction ? 'production' : 'development',
    ContentType: contentType,
  });
};

/**
 * A hook that tracks the page meta information on initial load
 * @returns a hook that tracks the page meta on initial load
 * */
export const useInitialPageMetaTracking = () => {
  const currentLocale = useCurrentLocale();
  useEffect(() => {
    // Track the page meta on initial load
    // Important: This should only be called once per page load, very early in the page lifecycle
    trackPageMeta(new URL(window.location.href), currentLocale);
  }, [currentLocale]);
};

/**
 * Newsletter tracking
 */

// the types of newsletters we support, e.g. Deutsche Grammophon or Stage+
type NewsletterType = 'DG' | 'StagePlus';

// the form ids of the newsletters for the analytics tracking
const newsletterFormIds: Record<NewsletterType, Record<SupportedLocale, string>> = {
  DG: {
    // the DG Newsletter is using the Formbuilder, so we need to use the form IDs
    en: '-Mq--z5403nAB9FJAit4',
    de: '-Mq-92RLb8MEjPgpkVwo',
    ja: '-Mq-99CtntXuwHBf66H5',
  },
  StagePlus: {
    // the Stage+ Newsletter is simply a form, so we can define our own names for the tracking
    en: 'Stage+ EN',
    de: 'Stage+ DE',
    ja: 'Stage+ JA',
  },
};

// the address book ids of the newsletters for the analytics tracking
const newsletterAdressBookIds: Record<NewsletterType, string> = {
  DG: '10531591',
  StagePlus: '',
};

/**
 * A hook that tracks the newsletter subscription process
 * @param newsletter - the type of the newsletter
 * @returns an object with a `onNewsletterStart` method that should be called when the user focuses on the email field
 * and a `onNewsletterValidationErrors` method that should be called when the user submits the form with validation errors
 * and a `onNewsletterSuccess` method that should be called when the user successfully signs up for the newsletter
 */
export const useNewsletterTracking = (newsletter: NewsletterType) => {
  const locale = useCurrentLocale();
  const newsletterName = newsletterFormIds[newsletter][locale] || newsletterFormIds[newsletter].en;
  const addressBookId = newsletterAdressBookIds[newsletter];
  const [startedNewsletterSignup, setStartedNewsletterSignup] = useState(false);

  // track when the user starts the newsletter sign up
  const onNewsletterStart = useCallback(() => {
    // but track only once
    if (!startedNewsletterSignup) {
      trackEvent({ eventName: 'NewsletterStart', newsletterName, addressBookId });
    }
    setStartedNewsletterSignup(true);
  }, [startedNewsletterSignup, newsletterName, addressBookId]);

  const onNewsletterValidationErrors = useCallback<SubmitErrorHandler<FieldValues>>(
    (errors) => {
      for (const error of Object.values(errors)) {
        if (error) {
          trackEvent({
            eventName: 'NewsletterError',
            newsletterName,
            error,
            addressBookId,
          });
        }
      }
    },
    [newsletterName, addressBookId],
  );

  const onNewsletterSuccess = useCallback(() => {
    trackEvent({ eventName: 'NewsletterSuccess', newsletterName, addressBookId });
  }, [addressBookId, newsletterName]);

  const onNewsletterConfirmation = useCallback(
    (success: boolean) => {
      trackEvent({
        eventName: 'NewsletterConfirmation',
        newsletterName,
        confirmationState: success ? 'success' : 'failure',
        addressBookId,
      });
    },
    [addressBookId, newsletterName],
  );

  return { onNewsletterStart, onNewsletterValidationErrors, onNewsletterSuccess, onNewsletterConfirmation };
};
