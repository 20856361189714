import { useEffect, useMemo, useState } from 'react';
import { LiveConcert, LiveConcertRerun } from 'generated/graphql';

type LiveConcertStateHookProps = Pick<LiveConcert, 'startTime' | 'endTime' | 'streamStartTime' | 'reruns'> & {
  stream?: LiveConcert['stream'] | undefined;
};
type LiveConcertStatus = 'upcoming' | 'pre-live' | 'live' | 'past';
type LiveConcertState = {
  /** The current status of the live concert */
  status: LiveConcertStatus;
  /** The stream start time of the currently live or next upcoming live concert time */
  streamStartTime?: LiveConcert['streamStartTime'];
  /** The start time of the currently live or next upcoming live concert time */
  startTime?: LiveConcert['startTime'];
  /** An array of upcoming reruns of the live concert based on the current timestamp */
  upcomingReruns: LiveConcert['reruns'];
  /** The currently active stream if the live concert is broadcasting now */
  activeStream?: LiveConcertRerun['stream'] | LiveConcert['stream'];
};
/**
 * Helper type to handle both live concert start-end times and rerun start-end times.
 * The timestamps are added to allow us to do faster Date.now() comparisons.
 */
type LiveConcertEvent = Pick<LiveConcert, 'startTime' | 'endTime' | 'streamStartTime'> & {
  startTimestamp: number;
  streamStartTimestamp: number;
  streamEndTimestamp: number;
  stream?: LiveConcertRerun['stream'] | LiveConcert['stream'];
};

const recalculateStateInterval = 1000;

/**
 * Helper function to check whether a live concert stream has already ended
 */
function isLiveConcertTimeEnded(time: LiveConcertEvent, timestamp: number): boolean {
  return time.streamEndTimestamp < timestamp;
}

/**
 * Helper function to check whether a live concert stream is currently in progress
 */
function isLiveConcertTimeInProgress(time: LiveConcertEvent, timestamp: number): boolean {
  return time.startTimestamp <= timestamp && timestamp <= time.streamEndTimestamp;
}

/**
 * Helper function to check whether a live concert stream is active but not yet started,
 * live concert usually has 15 mins or so of pre-live time
 */
function isLiveConcertTimeInPreparation(time: LiveConcertEvent, timestamp: number): boolean {
  return time.streamStartTimestamp <= timestamp && time.startTimestamp > timestamp;
}

/**
 * LiveConcert state hook to get the state of a LiveConcert based on the current time.
 * Every second, the hook will check the state and update it if needed. It will return
 * the status of the live concert, as well as the next start time and upcoming reruns.
 *
 * @example
 * ```tsx
 * const { status } = useLiveConcertState(liveConcert);
 * if (status === 'live') {
 *  // do something
 * }
 * ```
 */
export default function useLiveConcertState(props?: LiveConcertStateHookProps): LiveConcertState {
  const { startTime, endTime, streamStartTime, reruns, stream } = props || {
    // in some cases the hook is used in a component that treats all kinds of media, e.g. players
    // so to avoid conditional hook problems, we make the hook props optional
    startTime: '',
    streamStartTime: '',
    endTime: '',
    reruns: [],
    stream: undefined,
  };
  /**
   * Stores the current unix timestamp
   * Is updated on every tick using the `useEffect` hook below
   */
  const [timestamp, setTimestamp] = useState<number>(Date.now());
  /**
   * An array of upcoming LiveConcertTimes based on:
   * - initial start-end times
   * - rerun start-end times
   */
  const upcomingLiveConcertTimes: LiveConcertEvent[] = useMemo(
    (): LiveConcertEvent[] =>
      // merge original start-end times with rerun start-end times
      [
        {
          streamStartTime,
          startTime,
          endTime,
          stream,
          // using timestamps allows us to do fast comparisons
          streamStartTimestamp: new Date(streamStartTime || startTime).getTime(),
          startTimestamp: new Date(startTime).getTime(),
          streamEndTimestamp: new Date(endTime).getTime(),
        },
        ...reruns.map((rerun) => ({
          startTime: rerun.startTime,
          endTime: rerun.endTime,
          streamStartTime: rerun.streamStartTime,
          streamStartTimestamp: new Date(rerun.streamStartTime || rerun.startTime).getTime(),
          startTimestamp: new Date(rerun.startTime).getTime(),
          streamEndTimestamp: new Date(rerun.endTime).getTime(),
          stream: rerun.stream,
        })),
      ]
        // filter out any upcoming reruns that have already ended
        .filter((liveConcertTime) => !isLiveConcertTimeEnded(liveConcertTime, Date.now())),
    [streamStartTime, startTime, endTime, stream, reruns],
  );

  /**
   * Memoized LiveConcertTime that is currently in effect. This can be:
   * - the first upcoming live concert or rerun time
   * - the currently ongoing live concert or rerun time
   */
  const nextOrOngoingLiveConcertTime = useMemo((): LiveConcertEvent | undefined => {
    // get the first live concert time that has not yet ended
    return upcomingLiveConcertTimes.find((liveConcertTime) => !isLiveConcertTimeEnded(liveConcertTime, timestamp));
  }, [upcomingLiveConcertTimes, timestamp]);

  /**
   * Memoized LiveConcertStatus of the live concert based on:
   * - the next or ongoing live concert time
   * - the current timestamp
   */
  const liveConcertStatus = useMemo((): LiveConcertStatus => {
    // if the concert is in the past, return 'past'
    if (!nextOrOngoingLiveConcertTime) return 'past';
    if (isLiveConcertTimeInPreparation(nextOrOngoingLiveConcertTime, timestamp)) return 'pre-live';
    // if the concert is currently live or the stream has started, return 'live'
    if (isLiveConcertTimeInProgress(nextOrOngoingLiveConcertTime, timestamp)) return 'live';
    // else return 'upcoming'
    return 'upcoming';
  }, [nextOrOngoingLiveConcertTime, timestamp]);

  /**
   * Memoized array of upcoming reruns, excluding any past or currently ongoing reruns
   */
  const upcomingReruns: LiveConcertRerun[] = useMemo(() => {
    return (
      reruns
        // filter out any reruns that are in the past, and the next upcoming one
        .filter(
          (rerun) =>
            !(new Date(rerun.endTime).getTime() < timestamp) &&
            rerun.startTime !== nextOrOngoingLiveConcertTime?.startTime,
        )
    );
  }, [nextOrOngoingLiveConcertTime?.startTime, reruns, timestamp]);

  /**
   * Update the timestamp every second recalculate the LiveConcertState
   */
  useEffect(() => {
    if (!props) return;
    const tick = setInterval(() => setTimestamp(Date.now()), recalculateStateInterval);
    return () => {
      clearInterval(tick);
    };
  }, [props]);

  return {
    status: liveConcertStatus,
    streamStartTime: nextOrOngoingLiveConcertTime?.streamStartTime,
    startTime: nextOrOngoingLiveConcertTime?.startTime,
    activeStream:
      liveConcertStatus === 'live' || liveConcertStatus === 'pre-live'
        ? nextOrOngoingLiveConcertTime?.stream
        : undefined,
    upcomingReruns,
  };
}
