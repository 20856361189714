
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import { Fragment } from 'react';
import * as Sentry from '@sentry/nextjs';
import 'src/styles/globals.css';
import dynamic from 'next/dynamic';
import APIRoot from 'src/components/api-root';
import CssVariables from 'src/components/css-variables';
import DefaultHeadTags from 'src/components/default-head-tags';
import DevelopmentTools from 'src/components/development-tools';
import ErrorBoundaryFallback from 'src/components/errors/error-boundary-fallback';
import GlobalConsumers from 'src/components/global-consumers';
import PageLayout from 'src/components/layouts/page-layout';
import { VideoContextProvider } from 'src/state/video';
import { AppPropsWithLayout } from 'src/types';
/**
 * Use mock API for testing and offline development.
 * Is configured in next.config.mjs based on whether we are in a test environment
 */
if (process.env.NEXT_PUBLIC_MOCK_API === 'true') {
    // eslint-disable-next-line unicorn/prefer-module
    require('src/mocks');
}
/**
 * Exclude the client components like modals, notifications, player from the main bundle
 * by using the `dynamic` module loading. This should reduce both the load and execution times
 * on the first load of the app.
 */
const DynamicClientComponents = dynamic(() => import('src/components/app-client-components'));
/**
 * Define the main structure of the Web App
 * https://nextjs.org/docs/pages/building-your-application/routing/custom-app
 * To be replaced in the future with the Root Layout
 * https://nextjs.org/docs/app/building-your-application/routing/pages-and-layouts#root-layout-required
 */
const App = ({ Component, pageProps }: AppPropsWithLayout) => (<Fragment>
    <Sentry.ErrorBoundary fallback={(props) => <ErrorBoundaryFallback {...props}/>}>
      <APIRoot>
        <VideoContextProvider>
          <PageLayout limitedNavigation={Component.hideNavigation || false} headerComponent={Component.headerComponent} footerComponent={Component.footerComponent}>
            <DefaultHeadTags />
            <Component {...pageProps}/>
          </PageLayout>
          <DynamicClientComponents />
        </VideoContextProvider>
        <GlobalConsumers />
      </APIRoot>
    </Sentry.ErrorBoundary>
    <CssVariables />
    <DevelopmentTools />
  </Fragment>);
const __Next_Translate__Page__1916f7c44e2__ = App;

    export default __appWithI18n(__Next_Translate__Page__1916f7c44e2__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  