declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Window {
    dataLayer: unknown[];
    mixpanel?: { track: (parameter1: string, parameter2: Record<string, unknown>) => void };
  }
}

/**
 * the items in the dataLayer array are simple objects
 **/
type DataLayerItem = Record<string, unknown>;
/**
 * Push data to the Google Tag Manager
 * Docs: https://developers.google.com/tag-platform/tag-manager/web/datalayer
 */
export const pushToGoogleTagManager = (dataLayerObject: DataLayerItem) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(dataLayerObject);
};
