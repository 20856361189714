import { StateCreator, create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

// List of all currently available experiments
// !IMPORTANT! If you add or remove an experiment, make sure to bump the version number below
const experiments = [
  // keep a default experiment for integration tests and sanity checks
  'default',
] as const;

// Zustand persisted storage version number to avoid loading stale experiments from session storage
const version = 5;

export type Experiment = (typeof experiments)[number];

type ExperimentsState = {
  experiments: Experiment[];
  isEnabled: (experiment: Experiment) => boolean;
  toggle: (experiments: Experiment, onOff: boolean) => void;
};

const storageKey = 'experimentsState.zustand';

/**
 * Logging middleware to log the state of the experiments to the console
 */
const log: (config: StateCreator<ExperimentsState, [], []>) => StateCreator<ExperimentsState, [], []> =
  (config) => (set, get, store) => {
    const loggedSet: typeof set = (...a) => {
      set(...a);
      console.table(
        experiments.map((experiment) => ({
          experiment,
          status: get().isEnabled(experiment) ? 'enabled' : 'disabled',
        })),
      );
    };
    store.setState = loggedSet;

    return config(loggedSet, get, store);
  };

/**
 * Provide access to the locally stored experiments of the current user
 * e.g. if the user has enabled or disabled the stream token experiment
 */
export const useExperiments = create(
  persist<ExperimentsState>(
    log((set, get) => ({
      experiments: [],
      isEnabled: (experiment: Experiment) => get().experiments.includes(experiment),
      toggle: (experiment: Experiment, onOff: boolean) =>
        set((state) => {
          const experiments = new Set(state.experiments);
          if (onOff) {
            experiments.add(experiment);
          } else {
            experiments.delete(experiment);
          }
          return { experiments: [...experiments] };
        }),
    })),
    {
      name: storageKey,
      storage: typeof window === 'undefined' ? undefined : createJSONStorage(() => sessionStorage),
      version,
    },
  ),
);

/**
 * Check if a value is a valid experiment to avoid typos
 */
export function isValidExperiment(value: string): value is Experiment {
  return experiments.includes(value as Experiment);
}
