import { ComponentProps } from 'react';
import BigChevronIcon from '@stageplus/icons/react/big-chevron';
import Link from 'next/link';
import ContainerGrid from 'src/components/container-grid';
import FooterNewsletterForm from 'src/components/footer-newsletter-form';
import styles from 'src/components/footer.module.css';
import SocialLink from 'src/components/social-link';
import StoreLink from 'src/components/store-link';
import { useCurrentLocale } from 'src/hooks/use-current-locale';
import useTranslate from 'src/hooks/use-translate';
import { TranslationKeyCommon } from 'src/types';
import { getHelpCenterUrl } from 'src/utilities/help-center-helpers';

type FooterLegalLink = { title: TranslationKeyCommon; href: string | ComponentProps<typeof Link>['href'] };

const legalLinks: FooterLegalLink[] = [
  {
    title: 'footer__privacy',
    href: '/privacy',
  },
  {
    title: 'footer__terms',
    href: '/terms',
  },
  {
    title: 'footer__imprint',
    href: '/imprint',
  },
  {
    title: 'footer__cancel_subscription',
    href: '/account/login?redirect=%2Fsettings%2Fsubscription',
  },
];

const siemensLink = {
  title: 'Siemens Arts Program',
  // link to the partner page of the Siemens Arts Program
  href: '/partner/partner_A1GN4T3ECLP5UDO',
  imageSrc: '/images/sponsor-logo-siemens.svg',
};

const Footer = () => {
  const t = useTranslate();
  const currentLocale = useCurrentLocale();
  const currentYear = new Date().getFullYear();

  return (
    <footer className={styles.root} data-test="footer">
      {/* STAGE+ logo + Newsletter */}
      <div className="my-16 md:mb-12 md:mt-20">
        <ContainerGrid>
          <div className="col-span-4 col-start-2 md:col-span-4 md:col-start-1">
            <Link href="/" prefetch={false} className="mb-16 block md:mb-0">
              <img
                src="/images/logo.svg"
                alt={t('logo__title')}
                className="w-full select-none md:w-auto"
                width="310"
                height="64"
                loading="lazy"
              />
            </Link>
          </div>
          <div className="col-span-full md:col-span-7 md:col-start-6">
            <h3 className="dg-text-medium-3 mb-5">{t('footer__title_newsletter')}</h3>
            <FooterNewsletterForm />
          </div>
        </ContainerGrid>
      </div>

      {/* divider */}
      <ContainerGrid>
        <div className="col-span-full border-b border-dividerLight" />
      </ContainerGrid>

      {/* Grid with help, app download, social, and sponsor links */}
      <div className="my-14 md:my-20">
        <ContainerGrid>
          <div className="col-span-full mb-16 flex flex-col items-center md:col-span-3 md:mb-0 md:items-start">
            <p className="dg-text-regular-4 mb-4">{t('footer__title_help')}</p>
            <Link href={getHelpCenterUrl(currentLocale)} target="_blank" className="dg-text-medium-3 flex items-center">
              {t('footer__helpcenter')}
              <BigChevronIcon className="ml-1 text-brandYellowC1" />
            </Link>
          </div>
          <div className="col-start-2 col-end-6 mb-16 flex flex-col items-center md:col-span-3 md:mb-0 md:items-start">
            <p className="dg-text-regular-4 mb-4">{t('footer__title_download')}</p>
            <div className="max-w-44">
              <StoreLink storeName="apple" altText={t('component__store_link_apple_title')} />
            </div>
          </div>
          <div className="col-span-full mb-16 flex flex-col items-center md:col-span-3 md:mb-0 md:items-start">
            <p className="dg-text-regular-4 mb-4">{t('footer__title_social')}</p>
            <ul data-test="footer-social-links" className="flex w-full flex-wrap justify-center md:justify-start">
              {(['facebook', 'instagram', 'twitter', 'youtube'] as const).map((name) => (
                <li key={name} className="mb-2 mr-2 last:mr-0 lg:mr-3">
                  <SocialLink name={name} />
                </li>
              ))}
            </ul>
          </div>
          <div
            className="col-span-full flex flex-col items-center md:col-span-3 md:items-start"
            data-test="footer-sponsor"
          >
            <p className="dg-text-regular-4 mb-4">{t('footer__title_supported')}</p>
            <p className="dg-text-medium-3">
              <Link href={siemensLink.href}>
                <img
                  src={siemensLink.imageSrc}
                  width="172"
                  height="27"
                  alt={siemensLink.title}
                  className="inline-block select-none"
                />
              </Link>
            </p>
          </div>
        </ContainerGrid>
      </div>

      {/* divider */}
      <ContainerGrid>
        <div className="col-span-full border-b border-dividerLight" />
      </ContainerGrid>

      {/* Privacy Policy, Terms and conditions, and & Imprint links + copyright text */}
      <div className="my-14 lg:my-10">
        <ContainerGrid>
          <div className="col-span-full pb-6 text-center lg:col-span-6 lg:text-left">
            <div
              data-test="footer-links"
              className="flex flex-wrap justify-center lg:flex-nowrap lg:justify-start lg:space-x-6"
            >
              {/* Display a consent manager button */}
              <button
                onClick={() => window.__cmp?.('showScreen')}
                className="dg-text-regular-4 mx-3 mb-3 whitespace-nowrap lg:mx-0 lg:mb-0"
              >
                {t('footer__privacy_settings')}
              </button>
              {/* Render all legal links */}
              {legalLinks.map(({ href, title }) => (
                <Link
                  href={href}
                  prefetch={false}
                  key={title}
                  className="dg-text-regular-4 mx-3 mb-3 whitespace-nowrap lg:mx-0 lg:mb-0"
                >
                  {t(title)}
                </Link>
              ))}
            </div>
          </div>
          <div className="col-span-full text-center lg:col-span-6 lg:text-right">
            <p className="dg-text-regular-4 opacity-55" suppressHydrationWarning>
              &copy; Deutsche Grammophon, {currentYear}
            </p>
          </div>
        </ContainerGrid>
      </div>
    </footer>
  );
};

export default Footer;
