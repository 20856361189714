import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import { create, StoreApi, UseBoundStore } from 'zustand';
import { Album, LiveConcert, Track, Video, VodConcert } from 'generated/graphql';
import { LinkableNode } from 'src/utilities/url-helpers';

type ModalType =
  | 'info'
  | 'reminder'
  | 'share'
  | 'subscription'
  | 'subscription-confirmation'
  | 'cancelation-reasons'
  | undefined;

/**
 * Type specific modal data types define the specific data a certain modal needs
 */
export type InfoModalData = Pick<Album | LiveConcert | Video | VodConcert | Track, '__typename' | 'id'> | undefined;
export type ReminderModalData = Pick<LiveConcert, '__typename' | 'id'> | undefined;
export type ShareModalData = LinkableNode | undefined;

const useModalType = create<{ modalType: ModalType; setModalType: (type: ModalType) => void }>((set) => ({
  modalType: undefined,
  setModalType: (type: ModalType) => set({ modalType: type }),
}));

type ModalDataState<T> = { modalValue: T; setModalValue: (modalValue: T) => void };

/**
 * Zustand atom states hold the modal data in independent global states
 */

const infoModalDataState = create<ModalDataState<InfoModalData>>((set) => ({
  modalValue: undefined,
  setModalValue: (modalValue: InfoModalData) => set({ modalValue }),
}));

const reminderModalDataState = create<ModalDataState<ReminderModalData>>((set) => ({
  modalValue: undefined,
  setModalValue: (modalValue: ReminderModalData) => set({ modalValue }),
}));

const shareModalDataState = create<ModalDataState<ShareModalData>>((set) => ({
  modalValue: undefined,
  setModalValue: (modalValue: ShareModalData) => set({ modalValue }),
}));

const subscriptionModalDataState = create<ModalDataState<undefined>>((set) => ({
  modalValue: undefined,
  setModalValue: (modalValue: undefined) => set({ modalValue }),
}));

const subscriptionConfirmedModalDataState = create<ModalDataState<undefined>>((set) => ({
  modalValue: undefined,
  setModalValue: (modalValue: undefined) => set({ modalValue }),
}));

const subscriptionCanceledModalDataState = create<ModalDataState<undefined>>((set) => ({
  modalValue: undefined,
  setModalValue: (modalValue: undefined) => set({ modalValue }),
}));

/**
 * React hook that updates the global modal state based on a modal type and state
 */
function useModal<T>(type: ModalType, state: UseBoundStore<StoreApi<ModalDataState<T>>>) {
  const { events: routerEvents } = useRouter();
  const [modalType, setModalType] = useModalType((state) => [state.modalType, state.setModalType]);
  const [modalValue, setModalValue] = state((state) => [state.modalValue, state.setModalValue]);
  const isOpen = modalType === type;

  const open = useCallback(
    (state: T) => {
      setModalType(type);
      setModalValue(state);
    },
    [setModalType, setModalValue, type],
  );

  const close = useCallback(() => {
    setModalType(undefined);
  }, [setModalType]);

  // in case the modal is open but the app is navigating to another page, close the modal
  useEffect(() => {
    const handleRouteChange = () => {
      if (modalType) {
        close();
      }
    };
    routerEvents.on('routeChangeStart', handleRouteChange);
    return () => {
      routerEvents.off('routeChangeStart', handleRouteChange);
    };
  }, [modalType, close, routerEvents]);

  return { data: modalValue, isOpen, open, close };
}

/**
 * Type specific modal hooks
 */
export const useInfoModal = () => useModal<InfoModalData>('info', infoModalDataState);
export const useReminderModal = () => useModal<ReminderModalData>('reminder', reminderModalDataState);
export const useShareModal = () => useModal<ShareModalData>('share', shareModalDataState);
export const useSubscriptionModal = () => useModal<undefined>('subscription', subscriptionModalDataState);
export const useSubscriptionConfirmedModal = () =>
  useModal<undefined>('subscription-confirmation', subscriptionConfirmedModalDataState);
export const useCancelationReasonsModal = () =>
  useModal<undefined>('cancelation-reasons', subscriptionCanceledModalDataState);
