// pages that require user to be logged in
const requireAuthorizationPaths = [
  '/my-stage',
  '/settings',
  '/account/device',
  '/account/payment',
  '/account/email-changed',
  '/account/email-confirmed',
];

/**
 * A helper function to check if the current page requires user to be logged in
 * @param pathname  - a URL pathname to check, e.g. '/my-stage'
 */
export const hasToBeAuthorized = (pathname: string) =>
  requireAuthorizationPaths.some((route) => pathname.startsWith(route));

/**
 * A helper function that creates a URL to login page while maintaining the original request URL
 * @param requestUrl
 */
export const redirectToLogin = (requestUrl: string) => `/account/login?redirect=${encodeURIComponent(requestUrl)}`;
