import { useMemo } from 'react';
import { ClientError, GraphQLClient } from 'graphql-request';
import { getSdk, SdkFunctionWrapper } from 'generated/graphql';
import { audienceApi } from 'src/config';
import { useCurrentLocale } from 'src/hooks/use-current-locale';
import { useAuth, useToken } from 'src/state/auth';
import { SupportedLocale } from 'src/types';
import { GraphQLErrorType, matchesGraphQLErrorType } from 'src/utilities/api-helpers';
import { defaultLocale } from 'src/utilities/i18n-helpers';
export * from 'generated/graphql';

// Create a GraphQL client for the audience API that is used for all requests to the API.
// It is important this stays a singleton instance to allow the headers to be updated while the client might be used inside a closure.
// For example, when the auth token is expired, the sdk wrapper will refresh the token and retry the current request afterwards.
const client = new GraphQLClient(audienceApi.url);

/**
 * Returns a GraphQL client with the correct locale and auth headers.
 * Can be used for any request to the API.
 */
export function getClientWithHeaders({
  locale = defaultLocale,
  token,
}: {
  locale?: SupportedLocale;
  token?: string;
} = {}): GraphQLClient {
  // Replace headers with the current locale and token, overwriting any existing headers
  client.setHeaders({
    'Accept-Language': locale,
    ...(token && { Authorization: `Bearer ${token}` }),
  });

  return client;
}

type Sdk = ReturnType<typeof getSdk>;

/**
 * Returns an instance of the generated SDK based on a GraphQL client with the correct auth and locale headers.
 * It is used for any client-side requests to the API and is the only way to do authenticated requests. Failed
 * authentication requests are handled by a custom error handler.
 */
export default function useSdk(): Sdk {
  const { onTokenExpired, logout } = useAuth();
  const token = useToken();
  const locale = useCurrentLocale();

  // This is memoized so that the SDK instance is not recreated on every render, only when the token or locale changes
  return useMemo(() => {
    // Create a new GraphQL client with the correct locale and token headers
    const client = getClientWithHeaders({ locale, token });

    // A custom error handler that will refresh the token if it has expired or log the user out if the token is invalid
    const wrapper: SdkFunctionWrapper = async (action) => {
      try {
        // Run the request
        return await action();
      } catch (error) {
        // Check if the error is a ClientError (i.e. a GraphQL error)
        if (error instanceof ClientError && error.response.errors) {
          // Loop through the errors and check if any of them are a known error code
          for (const clientError of error.response.errors) {
            // Not found errors are thrown when the requested resource is not found
            if (matchesGraphQLErrorType(clientError, GraphQLErrorType.NotFound)) {
              // Don’t capture not found error, just throw it
              throw new Error(GraphQLErrorType.NotFound);
            }
            // Unauthenticated errors are thrown when access is denied due to missing or invalid token
            if (matchesGraphQLErrorType(clientError, GraphQLErrorType.Unauthenticated)) {
              // Log the user out and throw the error
              logout();
              throw error;
            }
            // Token expired errors are thrown when the token is expired
            if (matchesGraphQLErrorType(clientError, GraphQLErrorType.TokenExpired)) {
              // Refresh the token and retry the request
              await onTokenExpired();
              return action();
            }
          }
        }
        // Otherwise, if the error is not recognized, re-throw the error and let the caller or error boundary handle that
        throw error;
      }
    };
    return getSdk(client, wrapper);
  }, [locale, logout, onTokenExpired, token]);
}

/**
 * @important Only use this for non-localized API queries like `checkRegistrationData`.
 * In a server environment where you cannot use `useSdk`, please use `incrementalServerSideWithQuery` instead.
 */
export const sdk = getSdk(client);
export type SDK = typeof sdk;
