import { SupportedLocale } from 'src/types';
import { defaultLocale } from 'src/utilities/i18n-helpers';

const helpCenterBaseUrl = 'https://help.stage-plus.com/hc';
const helpCenterLocaleMap: Record<SupportedLocale, string> = {
  en: 'en-gb',
  de: 'de',
  ja: 'ja-jp',
};

/**
 * Returns the help center URL for the given locale
 */
export function getHelpCenterUrl(locale: SupportedLocale) {
  return `${helpCenterBaseUrl}/${helpCenterLocaleMap[locale] || helpCenterLocaleMap[defaultLocale]}`;
}
