import type { NextRequest, NextResponse } from 'next/server';
import Cookies from 'universal-cookie';

/**
 * A/B test type
 * @param name - the name of the test
 * @param totalVariants - the number of total variants in the test
 * @param description - the description of the test
 */
export type AbTest = {
  name: string;
  totalVariants: number;
  description: string;
};

/**
 * A/B test enrollment type
 * @param name - the name of the test
 * @param variant - the variant of the test
 * @example { name: 'xp2024-05-t1', variant: 'v1' }
 */
type AbTestEnrollment = { name: string; variant: string };

// the list of currently active ab tests
// if a test is removed from the list,
// the users that were enrolled in it will the cookie reset to empty value
export const activeAbTests = [] as const satisfies AbTest[];

// extract names of the active tests into a separate type
type ActiveAbTestNames = (typeof activeAbTests)[number]['name'];

// the name of the cookie that stores the info about the enrolled tests
const abTestsCookieName = 'stage-ab';
// how long the cookie should live once it is set
const cookieMaxLifetimeDays = 30;

// check if the app is in mock mode - running e2e tests
const isMocked = process.env.NEXT_PUBLIC_MOCK_API === 'true';

/**
 * Assign a variation number in a specific AB Test
 * @param totalVariations  - a number of total variations in the test
 */
function assignVariation(totalVariations: number) {
  return Math.floor(Math.random() * totalVariations + 1);
}

/**
 * Convert the list of tests to a string that can be stored in a cookie
 * @param tests - an array of active AB tests
 * @returns {string} - e.g. 'xp2024-05-t1:v1|xp2024-06-t2:v3'
 */
function testsToCookieValue(tests: AbTest[], testsEnrolled: AbTestEnrollment[] = []) {
  // extract the names of the tests that the user is already enrolled in
  const testsAlreadyEnrolled = testsEnrolled.map((x) => `${x.name}:${x.variant}`);
  // stringified list of tests that the user should be enrolled into
  const testsToEnrollInto = tests
    // filter out the tests that the user already has
    .filter((x) => !testsEnrolled.some((y) => y.name === x.name))
    // assign a random variation to the user in the remaining active tests
    .map((x) => `${x.name}:v${assignVariation(x.totalVariants)}`);
  // return the combined list of all tests
  return [...testsAlreadyEnrolled, ...testsToEnrollInto].join('|');
}

/**
 * Manage the AB-test cookies on the page requests in the middleware
 * Enroll the user in one of the variants of the tests that are currently active
 * Maintain the existing enrollment
 * Ignore the AB-test cookie if the user is running e2e tests
 */
export function withAbTestCookie(request: NextRequest, response: NextResponse, allTests: AbTest[]) {
  // if the app is mocked and running in E2E test mode, ignore the AB-test cookie
  if (isMocked) {
    return response;
  }
  // check if user already has an AB-test cookie
  const currentCookieValue = request.cookies.get(abTestsCookieName)?.value;
  // convert the current ab cookie value to an array of test enrollments
  // e.g. [] or ['xp2024-05-t1:v1', 'xp2024-06-t2:v3']
  // this is also makes sure to clean up the cookie from the expired tests
  // eslint-disable-next-line unicorn/no-array-reduce
  const testsEnrolled: AbTestEnrollment[] = (currentCookieValue?.split('|') || []).reduce<AbTestEnrollment[]>(
    (accumulator, test) => {
      const [name, variant] = test.split(':');
      // check if the previously enrolled test is still active
      if (allTests.some((x) => x.name === name)) {
        accumulator.push({
          name,
          variant,
        });
      }
      return accumulator;
    },
    [],
  );

  // store the updated cookie for the given amount of days
  response.cookies.set(abTestsCookieName, testsToCookieValue(allTests, testsEnrolled), {
    path: '/',
    maxAge: 1000 * 60 * 60 * 24 * cookieMaxLifetimeDays,
  });
  return response;
}

function getAbTestCookieValue() {
  const cookies = new Cookies();
  // Get A/B tests info from the cookie
  const cookieValue = cookies.get<string | undefined>(abTestsCookieName);
  return cookieValue;
}

/**
 * Get the list of all A-B tests that the current user is rolled-in
 * This method is used on the client
 */
export function getUsersAbTests(cookieValue = getAbTestCookieValue()): AbTestEnrollment[] | undefined {
  // the expected value is something like `xp2024-05-t1:v1|xp2024-06-t2:v3`
  return cookieValue?.split('|')?.map((test) => {
    const [name, variant] = test.split(':');
    return { name, variant };
  });
}

/**
 * Check if the user is enrolled in a particular experiment,
 * e.g. isInAbTest('experimentY') => 'v1' or isInAbTest('experimentX') => undefined
 */
export function isInAbTest(testName: ActiveAbTestNames) {
  const usersAbTests = getUsersAbTests();
  return usersAbTests?.find((test) => test.name === testName)?.variant;
}
