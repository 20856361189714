import { useCurrentLocale } from 'src/hooks/use-current-locale';
import { getAppStoreData, storeName } from 'src/utilities/mobile-apps-helpers';

const StoreLink = ({ storeName, altText }: { storeName: storeName; altText: string }): JSX.Element => {
  const locale = useCurrentLocale();

  const { url, logoSrc, width, height } = getAppStoreData(locale, storeName);
  return (
    <a data-test={`store-link-${storeName}`} href={url} className="dg-text-medium-3" target="appstore">
      <img
        src={logoSrc}
        width={width}
        height={height}
        alt={altText}
        className="inline-block select-none"
        loading="lazy"
      />
    </a>
  );
};

export default StoreLink;
